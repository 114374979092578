import { BraidProvider } from 'braid-design-system';
import seekJobs from 'braid-design-system/themes/seekJobs';
import type { PropsWithChildren } from 'react';

// @NOTE: This wrapper was created to centralise the logic of the NVL enabled switch
// between client and server render and so a react hook could be used. You don't need
// to keep this long term. When fully switching to NVL this wrapper could be removed
// and the BraidProvider can once again be used in the client and render directly
export const BraidProviderWrapper = ({ children }: PropsWithChildren) => (
  <BraidProvider theme={seekJobs}>{children}</BraidProvider>
);
