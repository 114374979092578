import loadable from 'sku/@loadable/component';

const ContactUs = loadable(() => import('../pages/contactus/App/App'));
const AdCentre = loadable(() => import('../pages/products/adcentre/App/App'));
const AreaFinder = loadable(() => import('../pages/tools/areafinder/App'));
const BrowserUpgrade = loadable(
  () => import('../pages/browser-upgrade/App/App'),
);
const Branding = loadable(() => import('../pages/products/branding/App/App'));

const ClearBrowserHistory = loadable(
  () =>
    // @ts-ignore
    import('../pages/clear-browser-history/App/App'),
);

const CompanyReviews = loadable(
  () =>
    // @ts-ignore
    import('../pages/products/companyreviews/App/App'),
);

const CompanyProfiles = loadable(
  () =>
    // @ts-ignore
    import('../pages/products/companyProfiles/App/App'),
);

const CompanyProfilesEnquiry = loadable(
  () =>
    // @ts-ignore
    import('../pages/products/companyProfilesEnquiry/App/App'),
);

const QRCode = loadable(
  () =>
    // @ts-ignore
    import('../pages/products/QRCode/App/App'),
);

const Forbidden = loadable(() => import('src/pages/error/forbidden/App'));

const GuaranteedHire = loadable(
  () =>
    // @ts-ignore
    import('../pages/products/guaranteed-hire/App/App'),
);

const InvalidVerification = loadable(
  () =>
    // @ts-ignore
    import('src/pages/error/invalid-verification/App'),
);

const NotFound = loadable(
  () =>
    // @ts-ignore
    import('../pages/error/notfound/App/App'),
);

const Maintenance = loadable(
  () =>
    // @ts-ignore
    import('../pages/error/maintenance/App/App'),
);

// @ts-ignore
const JobAds = loadable(() => import('../pages/products/jobads/App/App'));

const ServerError = loadable(
  () => import('src/pages/error/server-error/App/App'),
);
const TalentSearch = loadable(
  () =>
    // @ts-ignore
    import('../pages/products/talentsearch/App/App'),
);

const Training = loadable(() => import('../pages/training/App/App'));

export default {
  'qr-code': QRCode,
  adcentre: AdCentre,
  areafinder: AreaFinder,
  'browser-upgrade': BrowserUpgrade,
  branding: Branding,
  'clear-browser-history': ClearBrowserHistory,
  contactus: ContactUs,
  companyreviews: CompanyReviews,
  'company-profiles': CompanyProfiles,
  'company-profiles-enquiry': CompanyProfilesEnquiry,
  forbidden: Forbidden,
  'guaranteed-hire': GuaranteedHire,
  'invalid-verification': InvalidVerification,
  jobads: JobAds,
  maintenance: Maintenance,
  notfound: NotFound,
  'server-error': ServerError,
  talentsearch: TalentSearch,
  training: Training,
};
