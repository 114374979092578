import 'braid-design-system/reset';
import { HirerAuthProvider } from '@seek/hirer-auth-react';
import { HirerApolloProvider } from '@seek/hirer-graphql-react';
import { MelwaysProvider } from '@seek/melways-react';
import { UivBanner } from '@seek/uiv-banner';
import { VocabProvider } from '@vocab/react';
import React, { type FC } from 'react';
import { hydrateRoot } from 'react-dom/client';

import { BraidProviderWrapper } from 'src/components/BraidProviderWrapper/BraidProviderWrapper';

import configContext from './config-context';
import pageMap from './pages';
import type { ConfigContextTypes } from './types';

import 'intersection-observer';

const App = ({
  Component,
  config,
}: {
  Component: FC;
  config: ConfigContextTypes;
}) => {
  const { site, environment } = config;
  const graphUri =
    environment === 'development'
      ? 'https://talent.staging.seek.com.au/graphql'
      : '/graphql';

  return (
    <MelwaysProvider.Client site={site}>
      <configContext.Provider value={config}>
        <HirerAuthProvider>
          <HirerApolloProvider
            appName="wpt-talent-static-pages"
            site={site}
            uri={graphUri}
          >
            <VocabProvider language={config.language}>
              <BraidProviderWrapper>
                <UivBanner
                  applicationName="wpt-talent-static-pages"
                  branch={config.branch}
                  version={config.version}
                />
                <Component />
              </BraidProviderWrapper>
            </VocabProvider>
          </HirerApolloProvider>
        </HirerAuthProvider>
      </configContext.Provider>
    </MelwaysProvider.Client>
  );
};

export default (config: ConfigContextTypes) => {
  // @ts-ignore
  const Component = pageMap[config.routeName];

  hydrateRoot(
    document.getElementById('app')!,
    <App Component={Component} config={config} />,
  );
};
